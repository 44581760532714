import img1 from '../Assets/img1.png';
import img2 from '../Assets/img2.png';
import img3 from '../Assets/img3.png';

export const SliderData = [
  {
    image: img1,
  },
  {
    image: img2,
  },
  {
    image: img3,
  },
];
