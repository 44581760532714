import React from "react";
import "../Term/Term.css";
import Navbar from "../../Navbar/Navbar";

const Term = () => {
  return (
    <>
      <Navbar />
      <div className="term-main" style={{ width: "80%", margin: "auto" }}>
        <div className="term-main-sum">
          <h2>Terms and Conditions</h2>
          <h3>**For Everyone:**</h3>
          <ul>
            <li>Code of Ethics</li>
            <li>Actions We Take</li>
            <li>Modern Slavery and Human Trafficking Statement</li>
            <li>Anti-Bribery & Corruption Policy</li>
            <li>Modern Slavery Code of Conduct</li>
            <li>Copyright Dispute Policy</li>
            <li>Cookie Policy</li>
            <li>Accessibility Statement</li>
            <li>Terms of Use for Consumers</li>
          </ul>
        </div>

        <div className="term-each">
          <h3>**Introduction:**</h3>
          <p>
            CourseClipper is a universally accessible online review platform
            that bridges the gap between businesses and consumers, fostering
            trust and encouraging collaboration. We offer free access to all,
            embracing transparency as our guiding principle.
          </p>
          <p>
            When we mention "our platform," we are referring to the review
            platform hosted at courseclipper.com, its sub-domains,
            sub-directories, related platforms, websites, or mobile apps
            operated by us and provided to you.
          </p>

          <p>
            Before utilizing our platform, we kindly request that you read these
            Terms of Use (referred to as "the Terms" hereafter) to comprehend
            our expectations and what you can expect from us. These Terms also
            encompass the following policies, guidelines, and rules, all of
            which are applicable to your use of our platform:
          </p>
          <p>
            1. Our Privacy Policy, which clarifies the data we collect during
            platform use and its handling (further details in Section 12 below).
          </p>
          <p>
            2. Our Guidelines for Reviewers, which delineate proper usage of our
            platform (further details in Section 5 below).
          </p>
          <p>
            Whether you use CourseClipper to browse, search for businesses or
            reviews, or intend to submit reviews, it is essential to accept
            these Terms to clearly understand your legal rights and
            responsibilities. Your access and use of our platform are always
            contingent on your agreement with these Terms. Therefore, if you
            ever disagree or cannot comply with these Terms, you must refrain
            from accessing or using our platform. To reiterate, these Terms
            govern your use of our platform in all respects.
          </p>

          <p>
            You agree to these Terms by engaging in one or more of the following
            actions: (a) browsing, accessing, searching, writing a review, or
            otherwise using our platform; or (b) clicking on a checkbox or
            button, or providing any other form of positive affirmation that
            signifies your agreement to these Terms.
          </p>

          <p>
            It's worth noting that words or expressions defined within quotation
            marks maintain consistent meanings each time they appear in these
            Terms.
          </p>
        </div>

        <div className="term-each">
          <h3>**Joining and Utilizing CourseClipper**</h3>
          <p>Below, we outline how to access and utilize our platform:</p>
        </div>

        <div className="term-each">
          <h3>**You and CourseClipper:**</h3>
          <p>
            When we refer to "you" or "your," we are addressing the individuals
            who browse, access, search, write reviews, or otherwise use our
            platform. When we mention "CourseClipper," "we," "our," or "us," we
            are specifying the Trustpilot entity you contract with under these
            Terms, depending on your geographical location. For more information
            regarding the CourseClipper entity you are contracting with, please
            refer to Section 26, "Our Contracting Entities and Governing Law,"
            in these Terms.
          </p>
        </div>

        <div className="term-each">
          <h3>**Third-Party Products and Services:**</h3>
          <p>
            Our platform may incorporate, present, or link to third-party
            technology, products, and services. We neither endorse nor provide
            any guarantee or assurance, nor do we assume any responsibility for
            any third-party technology, products, and services. Please be aware
            that we cannot guarantee compatibility between our platform and your
            browser, network, or computer setup.
          </p>
        </div>

        <div className="term-each">
          <h3>**User Account:**</h3>
          <p>
            To post a review, comment on, like, or flag a review, or undertake
            any other action on our platform (excluding browsing), you must
            first create a user account. You are allowed to create only one user
            account, which is personal and non-transferable.
          </p>
          <p>
            Creating a user account typically involves signing in to the
            platform using your Facebook or Google account or providing your
            email address along with the verification code, which we will send
            to you. Sometimes, your account may be automatically generated and
            pre-filled with your name, such as when you click on a review
            invitation after purchasing a product or using a service, or when
            signing up via Facebook.
          </p>

          <p>
            Additionally, you will need to select a username that will be
            visible on the platform whenever you write a review, comment on a
            review, or contribute user-generated content, including reviews,
            images, or other content created by or originating from you on the
            platform. Your username serves as your identifier. Please note that
            if you use your real name as your username, your identity may be
            revealed. Your username must not contain:
            <ul>
              <li>
                Anything harmful, hateful, discriminatory, defamatory, obscene,
                or illegal.
              </li>
              <li>Terms such as "Guest," "Admin," ".dk," or ".com," etc.</li>
              <li>
                Characteristics belonging to someone else or a third party,
                including names other than your own.
              </li>
              <li>
                You are fully responsible for controlling access to and usage of
                your user account. For example: You must not permit any other
                individual to access or use your user account, and you should
                refrain from sharing your login details, including your email
                address and verification code, with anyone else. If you suspect
                that any of your user account login details have been stolen or
                misused, please inform us immediately by contacting us using the
                provided form.
              </li>
              <li>
                You pledge to keep all information associated with your user
                account (including your email address) up-to-date and complete.
                If you wish to change your username, you can do so independently
                by logging into your user account.
              </li>
              <li>
                You assume responsibility for all activities linked to your user
                account, including reviews, comments, and user-generated
                content.
              </li>
              <li>
                You may choose to verify your identity through our platform's
                quick and easy steps, as described in our Privacy Policy. The
                verification process typically involves selecting a photo ID,
                capturing an image of your chosen ID, and taking a selfie. Rest
                assured, your information is handled securely, as explained in
                our Privacy Policy.
              </li>
            </ul>
          </p>
        </div>

        <div className="term-each">
          <h3>**Your Key Responsibilities:**</h3>
          <p>
            When using our platform, you must adhere to the following
            requirements:
            <ol>
              <li>
                Use our platform exclusively in accordance with these Terms, our
                Guidelines for Reviewers, and our policies.
              </li>
              <li>Be at least 18 years old.</li>
              <li>
                Refrain from compromising the security or integrity of our
                platform.
              </li>
              <li>
                Avoid using our platform in a manner that may impair its
                functionality or disrupt others' usage.
              </li>
              <li>
                Abstain from introducing or uploading any content that contains
                viruses or other malicious code.
              </li>
              <li>
                Do not write, submit, or participate in the solicitation of fake
                reviews. By "fake reviews," we mean reviews that do not
                genuinely reflect a service or buying experience with a
                business, or reviews left with the intention of manipulating
                consumer perception or unfairly targeting a specific business.
              </li>
              <li>
                Refrain from engaging in any misleading, offensive, unlawful,
                rights-infringing, or non-compliant activities, as outlined in
                our Guidelines for Reviewers and policies.
              </li>
              <li>
                Avoid modifying, copying, adapting, reproducing, disassembling,
                decompiling, reverse engineering, or extracting the source code
                of any part of our platform.
              </li>
              <li>
                Refrain from engaging in abusive or disrespectful conduct
                towards Trustpilot employees, partners, customers, or other
                platform users. We have a zero-tolerance policy for abuse or
                harassment of CourseClipper employees in any context, including
                interactions with our support teams.
              </li>
              <li>
                Do not write reviews of a promotional nature or use our platform
                for marketing purposes, whether for yourself or any services,
                products, or businesses.
              </li>
              <li>
                Abstain from using our platform to develop or offer any service
                or functionality that competes with our platform.
              </li>
            </ol>
          </p>
        </div>

        <div className="term-each">
          <h3>**Our Guidelines for Reviewers and Policies:**</h3>
          <p>
            Our platform may incorporate, present, or link to third-party
            technology, products, and services. We neither endorse nor provide
            any guarantee or assurance, nor do we assume any responsibility for
            any third-party technology, products, and services. Please be aware
            that we cannot guarantee compatibility between our platform and your
            browser, network, or computer setup.
          </p>
        </div>
      </div>
    </>
  );
};

export default Term;
